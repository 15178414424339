<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { isNil } from "lodash";
import type { StateData } from "@planetadeleste/vue-mc-location/src/types";
import FormFieldState from "@/components/form/fields/State.vue";
import { LocationModule } from "@/store/location";
import FirmMixin from "@/mixins/FirmMixin";

@Component({
  components: { FormFieldState },
})
export default class FirmFieldState extends Mixins(FirmMixin) {
  get states() {
    return LocationModule.states.size() > 0;
  }

  onBlurState(sValue: string) {
    if (isNil(sValue)) {
      return;
    }

    this.setFirmValue("dgi_dpto_nom", sValue);
    this.setFirmValue("state_id", null);
    this.setFirmValue("state_code", null);
  }

  onSelectState(obData: StateData) {
    if (isNil(obData)) {
      return;
    }

    this.setFirmValue("dgi_dpto_nom", obData.name);
    this.setFirmValue("state_text", null);
    this.setFirmValue("state_code", null);
  }
}
</script>

<template>
  <v-slide-y-transition>
    <form-field-text
      v-if="!states"
      v-model="firm.state_text"
      :required="required"
      label="location.state"
      @blur="onBlurState"
    />
    <form-field-state
      v-else
      v-model="firm.state_id"
      :country-id="firm.country_id"
      :required="required"
      return-object-on-change
      @change="onSelectState"
    />
  </v-slide-y-transition>
</template>
