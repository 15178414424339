<template>
  <firm-dgi-form v-model="obFirm" v-bind="$attrs" />
</template>

<script lang="ts">
import { Mixins, Component } from "vue-property-decorator";
import FirmDgiForm from "@/modules/companies/components/FirmDgiForm.vue";
import CustomerMixin from "@/modules/customers/mixins/CustomerMixin";

@Component({ components: { FirmDgiForm } })
export default class CustomerDgi extends Mixins(CustomerMixin) {}
</script>
