<template>
  <v-row>
    <v-col v-if="!hideFields.includes('dgi_dir_fiscal')" cols="12" lg="6">
      <firm-field-address :firm="obFirm" :required="requireDgi" />
    </v-col>

    <v-col v-if="!hideFields.includes('dgi_local_sec_nro')" cols="12" lg="3">
      <form-field-text
        v-model="obFirm.dgi_local_sec_nro"
        :required="requireDgi"
        label="legal.address.home.number"
      />
    </v-col>

    <v-col cols="12" lg="3">
      <form-field-date-picker
        :required="requireDgi"
        :value="sLocalUpdatedAt"
        :max="maxLocalUpdatedAt"
        label="legal.address.home.number.date"
        @change="onSetLocalUpdatedAt"
      />
    </v-col>

    <v-col v-if="!hideFields.includes('country_id')" cols="12" lg="4" md="6">
      <form-field-country
        v-model="obFirm.country_id"
        :codes="validCountryCodes"
        :required="requireDgi"
        @change="onSelectCountry"
      />
    </v-col>

    <v-col v-if="!hideFields.includes('state_id')" cols="12" lg="4" md="6">
      <firm-field-state :firm="obFirm" :required="requireDgi" />
    </v-col>

    <v-col v-if="!hideFields.includes('town_id')" cols="12" lg="4" md="6">
      <firm-field-town :firm="obFirm" :required="requireDgi" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import type { FirmData } from "@planetadeleste/vue-mc-gw";
import { Firm } from "@planetadeleste/vue-mc-gw";
import { Component, Prop, VModel, Vue } from "vue-property-decorator";

import FormFieldCountry from "@/components/form/fields/Country.vue";
import FormFieldState from "@/components/form/fields/State.vue";
import FormFieldTown from "@/components/form/fields/Town.vue";
import type { IDListKey } from "@/services/Utils";
import Utils from "@/services/Utils";
import { LocationModule } from "@/store/location";
import dayjs from "dayjs";
import FormFieldDatePicker from "@/components/form/fields/DatePicker.vue";
import FirmFieldTown from "@/components/form/fields/FirmFieldTown.vue";
import FirmFieldState from "@/components/form/fields/FirmFieldState.vue";
import FirmFieldAddress from "@/components/form/fields/FirmFieldAddress.vue";

@Component({
  components: {
    FirmFieldAddress,
    FirmFieldState,
    FirmFieldTown,
    FormFieldDatePicker,
    FormFieldState,
    FormFieldTown,
    FormFieldCountry,
  },
})
export default class FirmDgiForm extends Vue {
  @VModel({ type: [Object, Array], default: () => ({}) })
  obFirm!: Firm | Partial<FirmData>;

  @Prop(Boolean) readonly requireDgi!: boolean;
  @Prop({ type: Array, default: () => [] }) readonly hideFields!: Array<
    keyof FirmData
  >;

  get validCountryCodes() {
    if (!this.obFirm.doc_type) {
      return [];
    }

    return Utils.validateCountryByID(this.obFirm.doc_type as IDListKey);
  }

  get obFirmModel(): Firm {
    return this.obFirm instanceof Firm
      ? this.obFirm
      : new Firm(this.obFirm as Partial<FirmData>);
  }

  get sLocalUpdatedAt(): string {
    const obDate = this.obFirm.dgi_local_updated_at
      ? dayjs(this.obFirm.dgi_local_updated_at)
      : dayjs();

    return obDate.format("YYYY-MM-DD");
  }

  get maxLocalUpdatedAt(): string {
    return dayjs().format("YYYY-MM-DD");
  }

  onSetLocalUpdatedAt(sValue: string) {
    if (!this.obFirm) {
      return;
    }

    if (this.obFirm instanceof Firm) {
      this.obFirm.set("dgi_local_updated_at", sValue);
      return;
    }

    this.obFirm.dgi_local_updated_at = sValue;
    this.obFirmModel.set("dgi_local_updated_at", sValue);
  }

  onSelectCountry(iValue: number) {
    LocationModule.loadStates(iValue);
    LocationModule.towns.clear();
  }
}
</script>
