<template>
  <ValidationProvider :name="$t(sLabel)" :rules="sRules" :vid="sVid" slim>
    <template #default="{ errors, valid }">
      <v-autocomplete
        v-model="countryId"
        :error-messages="errors"
        :items="items"
        :success="required ? valid : undefined"
        v-bind="obAttrs"
        @change="onSelect"
      />
    </template>
  </ValidationProvider>
</template>
<script lang="ts">
import { LocationModule } from "@/store/location";
import type { CountryData } from "@planetadeleste/vue-mc-location";
import { camelCase, map } from "lodash";
import { Component, Mixins, Prop, VModel } from "vue-property-decorator";
import SelectFieldMixin from "@/mixins/SelectFieldMixin";

@Component
export default class FormFieldCountry extends Mixins(SelectFieldMixin) {
  @VModel({ type: [Number, String] }) countryId!: number;

  @Prop({ type: Array, default: () => [] }) readonly codes!: string[];

  sLabelDefault: string = "location.country";

  get items() {
    return map(
      // @ts-ignore
      this.countries.getModelList(),
      (obModelData: Partial<CountryData>) => {
        return {
          text: obModelData.name,
          value: obModelData.id,
          disabled:
            !!this.codes.length &&
            !!obModelData.code &&
            !this.codes.includes(obModelData.code),
        };
      }
    );
  }

  get countries() {
    return LocationModule.countries;
  }

  get sRules() {
    let sRules = "";
    if (this.required && !sRules.includes("required")) {
      sRules = sRules.length ? `required|${sRules}` : "required";
    }
    return sRules;
  }

  get sVid() {
    return camelCase(this.label);
  }

  onSelect(iValue: number) {
    this.$emit("input", iValue);
    this.$emit("change", iValue);
  }
}
</script>
