<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import FirmMixin from "@/mixins/FirmMixin";
import { isNil } from "lodash";

@Component({})
export default class FirmFieldAddress extends Mixins(FirmMixin) {
  onBlurAddress(sValue: string) {
    if (isNil(sValue)) {
      return;
    }

    this.setFirmValue("address", sValue);
    this.setFirmValue("dgi_dir_fiscal", sValue);
  }
}
</script>

<template>
  <form-field-text
    :required="required"
    :value="firm.address"
    label="legal.address"
    @blur="onBlurAddress"
  />
</template>
