<template>
  <ValidationProvider
    ref="textValidator"
    :name="$t(sLabel)"
    :rules="sRules"
    :vid="sVid"
    slim
  >
    <template #default="{ errors, valid }">
      <v-autocomplete
        v-model="townId"
        :error-messages="errors"
        :items="towns.getModelList()"
        :loading="bLoading"
        :success="required ? valid : undefined"
        item-text="name"
        item-value="id"
        v-bind="obAttrs"
        @change="onSelect"
      ></v-autocomplete>
    </template>
  </ValidationProvider>
</template>
<script lang="ts">
import { LocationModule } from "@/store/location";
import { Component, Mixins, Prop, VModel, Watch } from "vue-property-decorator";
import SelectFieldMixin from "@/mixins/SelectFieldMixin";
import type { TownData } from "@planetadeleste/vue-mc-location/src/types";
import { find } from "lodash";

@Component
export default class FormFieldTown extends Mixins(SelectFieldMixin) {
  @VModel({ type: [Number, String] }) townId!: number;
  @Prop([Number, String]) readonly stateId!: number;
  @Prop(Boolean) readonly returnObjectOnChange!: boolean;

  sLabelDefault: string = "location.town";
  bLoading = false;

  get items() {
    return this.towns.getModelList() as TownData[];
  }

  get towns() {
    return LocationModule.towns;
  }

  get sRules() {
    let sRules = "";
    if (this.required && !sRules.includes("required")) {
      sRules = sRules.length ? `required|${sRules}` : "required";
    }
    return sRules;
  }

  get sVid() {
    return this.$_.camelCase(this.label);
  }

  mounted() {
    this.$nextTick(this.loadTowns);
  }

  onSelect(iValue: number) {
    this.$emit("input", iValue);
    this.$emit(
      "change",
      this.returnObjectOnChange ? find(this.items, { id: iValue }) : iValue
    );
  }

  @Watch("stateId", { immediate: true })
  onStateIDChange(iValue: number) {
    if (iValue) {
      this.loadTowns();
    }
  }

  async loadTowns() {
    this.bLoading = true;
    await LocationModule.loadTowns(this.stateId);
    this.bLoading = false;
  }
}
</script>
