<template>
  <ValidationProvider :name="$t(sLabel)" :rules="sRules" :vid="sVid" slim>
    <template #default="{ errors, valid }">
      <v-autocomplete
        v-model="stateId"
        :error-messages="errors"
        :items="items"
        :success="required ? valid : undefined"
        item-text="name"
        item-value="id"
        v-bind="obAttrs"
        @change="onSelect"
      ></v-autocomplete>
    </template>
  </ValidationProvider>
</template>
<script lang="ts">
import { LocationModule } from "@/store/location";
import { Component, Mixins, Prop, VModel, Watch } from "vue-property-decorator";
import SelectFieldMixin from "@/mixins/SelectFieldMixin";
import type { StateData } from "@planetadeleste/vue-mc-location/src/types";
import { find } from "lodash";

@Component
export default class FormFieldState extends Mixins(SelectFieldMixin) {
  @VModel({ type: [Number, String] }) stateId!: number;
  @Prop([Number, String]) readonly countryId!: number;
  @Prop(Boolean) readonly returnObjectOnChange!: boolean;

  sLabelDefault: string = "location.state";

  get items() {
    return this.states.getModelList() as StateData[];
  }

  get states() {
    return LocationModule.states;
  }

  get sRules() {
    let sRules = "";
    if (this.required && !sRules.includes("required")) {
      sRules = sRules.length ? `required|${sRules}` : "required";
    }
    return sRules;
  }

  get sVid() {
    return this.$_.camelCase(this.label);
  }

  mounted() {
    this.$nextTick(this.loadStates);
  }

  onSelect(iValue: number) {
    this.$emit("input", iValue);
    this.$emit(
      "change",
      this.returnObjectOnChange ? find(this.items, { id: iValue }) : iValue
    );
  }

  @Watch("countryId", { immediate: true })
  onCountryIDChange(iValue: number, iOldValue?: number) {
    if (iValue != iOldValue) {
      this.loadStates();
    }
  }

  async loadStates() {
    await LocationModule.loadStates(this.countryId);
    LocationModule.towns.clear();
  }
}
</script>
