<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { isNil } from "lodash";
import type { TownData } from "@planetadeleste/vue-mc-location/src/types";
import { LocationModule } from "@/store/location";
import FormFieldTown from "@/components/form/fields/Town.vue";
import FirmMixin from "@/mixins/FirmMixin";

@Component({
  components: { FormFieldTown },
})
export default class FirmFieldTown extends Mixins(FirmMixin) {
  get towns() {
    return LocationModule.towns.size() > 0;
  }

  onBlurTown(sValue: string) {
    if (isNil(sValue)) {
      return;
    }

    this.setFirmValue("dgi_loc_nom", sValue);
    this.setFirmValue("town_id", null);
    this.setFirmValue("town_slug", null);
  }

  onSelectTown(obData: TownData) {
    if (isNil(obData)) {
      return;
    }

    this.setFirmValue("dgi_loc_nom", obData.name);
    this.setFirmValue("town_text", null);
  }
}
</script>

<template>
  <v-slide-y-transition>
    <form-field-text
      v-if="!towns"
      v-model="firm.town_text"
      :required="required"
      label="location.town"
      @blur="onBlurTown"
    />
    <form-field-town
      v-else
      v-model="firm.town_id"
      :required="required"
      :state-id="firm.state_id"
      return-object-on-change
      @change="onSelectTown"
    />
  </v-slide-y-transition>
</template>
